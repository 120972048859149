import TextInput from 'components/admin2/TextInput';
import { Dropdown } from 'components/admin2/ui/Dropdown';
import Label from 'components/admin2/ui/Label';
import { ADMIN_ACCENT_PRIMARY, ADMIN_SURFACE_3 } from 'style/constants';
import { ADMIN_TEXT_BODY_XS_REGULAR } from 'style/design-system/textStyles';
import styled from 'styled-components';

export const FolderParentPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  width: 100%;
`;

export const StyledDropdown = styled(Dropdown)`
  width: 100%;
`;
