import React from 'react';
import { useSelector } from 'react-redux';
import { isEditMode } from 'services/admin/selectors';
import { isEqual } from 'lodash';
import { IEditableAreaProps } from 'components/admin2/ui/EditableArea';
import { ToolbarButtons } from 'components/ui/RichTextEditorV2/utils';
import { StyledEditableArea } from './styles';
import RichTextEditorV2 from 'components/ui/RichTextEditorV2/RichTextEditorV2';
import RichTextEditorV3 from 'components/ui/RichTextEditorV3';

interface IInlineTextEditorProps {
  blockTitle?: IEditableAreaProps['blockTitle'];
  blockTitleKey?: IEditableAreaProps['blockTitleKey'];
  borderStyle?: IEditableAreaProps['borderStyle'];
  className?: string;
  data?: string;
  'data-testid'?: string;
  editorActions?: IEditableAreaProps['editorActions'];
  editorActionsCss?: IEditableAreaProps['editorActionsCss'];
  htmlTag?: keyof JSX.IntrinsicElements;
  maxLength?: number;
  maxLines?: number;
  onChange?: (text: string) => void;
  readOnly?: boolean;
  toolbarButtons: ToolbarButtons;
  useRawDataV2?: boolean; // temporary
}

const InlineTextEditor = ({
  className,
  maxLength = 1000,
  onChange,
  data: initialData = '',
  'data-testid': testId = 'text-editor-input',
  blockTitle,
  blockTitleKey,
  borderStyle,
  editorActions,
  editorActionsCss,
  readOnly,
  toolbarButtons,
  htmlTag,
  useRawDataV2,
}: IInlineTextEditorProps) => {
  const [data, setData] = React.useState(initialData);
  const inEditMode = useSelector(isEditMode);

  React.useEffect(() => {
    if (!isEqual(initialData, data)) {
      setData(initialData);
    }
  }, [initialData]);

  const handleChange = React.useCallback((newData: string | null) => {
    if (newData && !isEqual(newData, data) && onChange) {
      setData(newData);
      onChange(newData);
    }
  },[setData, onChange]);

  return (
    <StyledEditableArea
      blockTitle={blockTitle}
      blockTitleKey={blockTitleKey}
      borderStyle={borderStyle}
      editorActions={editorActions}
      className={className}
      editorActionsCss={editorActionsCss}
      testId={testId}
      isEditing={inEditMode && !readOnly}
    >
      {
        useRawDataV2 ? (
          <RichTextEditorV3
            readOnly={readOnly}
            data={initialData}
            onChange={handleChange}
            inline={true}
            sticking={false}
          />
        ) : (
          <RichTextEditorV2
            readOnly={readOnly}
            isEditMode={inEditMode}
            disableDebounce={true}
            maxLength={maxLength}
            onChange={handleChange}
            data={data}
            inline={true}
            toolbarButtons={toolbarButtons}
            htmlTag={htmlTag}
          />
        )}
    </StyledEditableArea>
  );
};

export default React.memo(InlineTextEditor);
