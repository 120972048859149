// tslint:disable: jsx-boolean-value
import React, { useEffect, HTMLAttributes, useMemo } from 'react';
import Modal from 'react-modal';
import styled, { css } from 'styled-components';
import { Transition } from 'react-transition-group';
import type { TransitionStatus } from 'react-transition-group/Transition';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAdminBarWidth,
} from 'services/admin/selectors';
import {
  addAdminModal,
  popAdminModal,
} from 'services/admin/actions';
import {
  ADMIN_TEXT_100,
  ADMIN_SURFACE_4,
} from 'style/constants';
import {
  ADMIN_SCROLL_BAR,
} from 'style/mixins';
import { ADMIN_TEXT_BODY_XS_REGULAR } from 'style/design-system/textStyles';

type StyledModalProps = {
  fixedDimensions?: boolean;
  fixedHeight?: boolean;
  fixedWidth?: boolean;
  maxHeight?: string;
  maxWidth?: string;
  overflow?: string | null;
  state: TransitionStatus;
};

// TODO: Less janky "inheritance" of global styles (i.e. BODY_FONT)
const StyledModal = styled(Modal)<StyledModalProps>`
  ${ADMIN_SCROLL_BAR}
  overflow: hidden auto !important;
  background: ${ADMIN_SURFACE_4};
  border: none;
  border-radius: 10px;
  position: fixed;
  overflow: ${props => props.overflow || 'hidden auto'};
  max-width: 90vw;
  max-height: 90vh;

  ${({ fixedDimensions, fixedHeight, fixedWidth, maxWidth, maxHeight }) => !fixedDimensions && css`
    ${!fixedWidth && css`width: min(80%, 1000px);`}
    ${!fixedWidth && !maxWidth && css`min-width: max(60%, 800px);`}
    ${!fixedWidth && !maxWidth && css`max-width: min(80%, 1000px);`}

    ${!fixedHeight && css`height: min(90%, 920px);`}
    ${!fixedHeight && !maxHeight && css`min-height: max(60%, 680px);`}
    ${!fixedHeight && !maxHeight && css`max-height: min(90%, 920px);`}

    ${maxWidth && css`max-width: ${maxWidth};`}
    ${maxHeight && css`max-height: ${maxHeight};`}
    & > * {
      max-width: 100% !important;
      max-height: 100% !important;
      min-width: 0;
    }
  `}
  &:focus {
    outline: none;
  }
  ${ADMIN_TEXT_BODY_XS_REGULAR};
  color: ${ADMIN_TEXT_100};
  transition: 0.5s;
  opacity: ${({ state }) => state === 'entered' ? 1 : 0};
`;

const Level = React.createContext(0);

interface AdminModalProps extends Pick<HTMLAttributes<HTMLElement>, 'children' | 'className'> {
  allowOverflow?: boolean;
  fixedDimensions?: boolean;
  fixedHeight?: boolean;
  fixedWidth?: boolean;
  id?: string;
  maxHeight?: string;
  maxWidth?: string;
  onClose?: () => void;
  onOpen?: () => void;
  overlapAdminbar?: boolean;
  overlayStyles?: Record<string, any>;
}

export default function AdminModal({
  allowOverflow,
  children,
  className,
  fixedDimensions,
  fixedHeight,
  fixedWidth,
  id,
  maxHeight,
  maxWidth,
  onClose,
  onOpen,
  overlapAdminbar,
  overlayStyles,
  ...props
}: AdminModalProps) {
  const dispatch = useDispatch();
  const adminbarWidth = useSelector(getAdminBarWidth);
  const rootStyle = useMemo(() => ({
    overlay: {
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      marginLeft: 0,
      transition: '0.5s',
      zIndex: 1000,
      ...overlayStyles,
    },
  }), [adminbarWidth]);

  const addModal = () => {
    dispatch(addAdminModal());
  };

  const popModal = () => {
    dispatch(popAdminModal());
  };

  useEffect(() => {
    addModal();
    return () => {
      popModal();
    };
  }, []);

  return (
    <Level.Consumer>
      {level => (
        <Level.Provider value={level}>
          <Transition appear in timeout={0}>
            {(state) => (
              <StyledModal
                ariaHideApp={false}
                className={className}
                fixedDimensions={fixedDimensions}
                fixedHeight={fixedHeight}
                fixedWidth={fixedWidth}
                id={id}
                isOpen
                maxHeight={maxHeight}
                maxWidth={maxWidth}
                onAfterOpen={onOpen}
                onRequestClose={onClose}
                overflow={allowOverflow ? 'visible !important' : null}
                state={state}
                style={rootStyle}
                {...props}
              >
                {children}
              </StyledModal>
            )}
          </Transition>
        </Level.Provider>
      )}
    </Level.Consumer>
  );
}
